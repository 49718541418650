<template>
  <div>
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="`Comentários | Instalação ${installationId} | ${installationName}`"
      :modal="true"
      :closable="false"
    >
      <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
        <div class="p-field p-col-12 p-md-12">
          <DataTable
            :value="commentsList"
            responsiveLayout="scroll"
            class="p-datatable-sm"
            :loading="loading"
            :sortOrder="-1"
            :sortField="'created_at'"
          >
            <template #empty>
              Nada a mostrar. Selecione no menu em cima o que pretende
              visualizar.
            </template>
            <template #loading>
              A carregar dados. Por favor Aguarde...
            </template>
            <Column field="comment" header="Comentário"></Column>
            <Column
              header="Criado"
              field="created_at"
              :bodyStyle="{ 'text-align': 'center' }"
              :headerClass="'align-center'"
            >
              <template #body="slotProps">
                {{ slotProps.data.created_at }}
                <br />
                {{ slotProps.data.created_by }}
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
      <template #footer>
        <Button
          label="Fechar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import installationService from "../../services/installations.service";
export default {
  name: "InstallationComments",
  props: ["show", "installationName", "installationId"],
  watch: {
    show() {
      if (this.show == false) {
        return (this.commentsList = []);
      }
      return this.getComments();
    },
  },
  data() {
    return {
      commentsList: [],
      loading: false,
    };
  },
  methods: {
    cancel() {
      return this.$emit("cancel");
    },
    getComments() {
      this.loading = true;
      return installationService
        .getInstallationComments(this.installationId)
        .then((response) => {
          this.commentsList = response.comments;
          return (this.loading = false);
        });
    },
  },
};
</script>
<style>
.align-center .p-column-header-content {
  justify-content: center !important;
}
th.align-center {
  justify-content: center !important;
}
</style>
